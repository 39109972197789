import * as React from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout.js'
import HeroShort from '../components/hero-short.js'
import Contact from '../components/contact.js'

const WorkPage = () => {
  return (
    <Layout>

    <Helmet>
      <title>Freelance Design and Development Services | Billy Dawson</title>
      <meta property="description" content="Looking for a new design or development freelancer in Brighton? Take a quick look around my site and get in touch about your next project." />
    </Helmet>

      <HeroShort title="What I Do 🖥" />

      <div className="section main">
        <div className="wrap container">
          <div className="row">
            <div className="col-xs-12 col-md-8 blog-list">

              <div className="blog-item">
                <h3>WordPress Development</h3>
                <p>I create bespoke, lightweight WordPress sites from the ground up. No bulky code or heavy plugins. Whether you need a completely new site, or some tidying done on your current one, I can help.</p>
                <p><a href="/freelance-wordpress-developer/">Find out more</a></p>
              </div>

              <div className="blog-item">
                <h3>Website Development</h3>
                <p>If you need a new bespoke website building from scratch or just some changes making to existing platform, I can lend a hand.</p>
                <p><a href="/web-developer-brighton/">Find out more</a></p>
              </div>

            </div>

          </div>
        </div>
      </div>


      <Contact colour="yellow" />

    </Layout>
  )
}

export default WorkPage
